import React from 'react';
import * as _ from "lodash";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CircularProgress from "@material-ui/core/CircularProgress";
import Card from "../common/Card";
import GeneralResult from "../GeneralResult";

class GeneralResults extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true
        };
    }

    handleDisableLoader = () => {
        this.setState({
            isLoading: false
        });
    };

    render() {
        const periods = this.props.periods;
        let totalPeriods = periods.length;
        let lastModification = this.props.lastModification;
        const LastModificationComponent = () => (
            <span><AccessTimeIcon/>{'   Última modificación: ' + lastModification}</span>
        );
        return (
            <div className={"row pt-5"}>
                <div className={"col-md-12"}>
                    <Card
                        cardHeader={
                            {
                                title: "Resultados",
                                subheader: <LastModificationComponent/>,
                                action: (
                                    <CircularProgress color={"primary"} hidden={!this.state.isLoading}/>
                                )
                            }
                        }
                        cardContent={
                            <div className={"text-center"} style={{minHeight: "250px"}}>
                                {
                                    _.orderBy(periods, ['id'], ['asc']).map((period, index) => {
                                        return (
                                            <GeneralResult
                                                key={'gr-' + period.id}
                                                dashboardId={this.props.dashboardId}
                                                dashboardName={this.props.dashboardName}
                                                period={period}
                                                semaphore={this.props.semaphore}
                                                decimals={this.props.decimals}
                                                typeCalculation={this.props.typeCalculation}
                                                locationsStr={this.props.locationsStr}
                                                toDisableLoader={index === (totalPeriods - 1)}
                                                handleDisableLoader={this.handleDisableLoader}
                                            />
                                        )
                                    })
                                }
                            </div>
                        }
                    />
                </div>
            </div>
        );
    }

}

export default GeneralResults;
