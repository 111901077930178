import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

const useStyles = theme => ({
    grow: {
        flexGrow: 1,
    },
    title: {
        [theme.breakpoints.up('sm')]: {
            fontSize: '18px',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px'
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    }
});

class SurveyTitleBar extends React.Component {

    render() {
        const {classes} = this.props;
        return (
            <div className={classes.grow}>
                <AppBar position="fixed" color={"primary"}>
                    <Toolbar>
                        <div className={classes.sectionDesktop}>
                            <div style={{
                                borderRadius: '0px 0px 60px 0px',
                                backgroundColor: '#fff',
                                width: '260px',
                                height: '64px',
                                marginTop: '-1px',
                                marginRight: '15px'
                            }} className={"text-center"}>
                                <div color="inherit" aria-label="Logo" style={{marginTop: '-13px !important'}}>
                                    <img style={{width: "auto", height: "64px"}} src={'https://elasticbeanstalk-us-east-1-460503004594.s3.amazonaws.com/upaxer/administrator/resources/logoUpaxer.png'} alt={"Logo"}/>
                                </div>
                            </div>
                        </div>

                        <div className={"pl-2"}>
                            <Typography variant="h6" component={'div'} className={classes.title}>
                                {'Encuesta socioeconómica'}
                            </Typography>
                        </div>

                        <div className={classes.grow}/>
                    </Toolbar>
                </AppBar>
            </div>
        );
    }

}

export default withStyles(useStyles)(SurveyTitleBar);