import React from 'react';
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core";
import LoaderFullscreen from "../components/common/loader/Fullscreen";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import TitleBar from "../components/TitleBar";
import {ExecutiveDashboardServices} from "../api/service";
import GeneralResults from "../components/GeneralResults";
import Progress from "../components/Progress";
import Rank from "../components/Rank";
import ScoredByFilter from "../components/ScoreByFilter";
import Filters from "../components/Filters";
import SnackbarContentWrapper from "../components/common/Snackbar";
import Snackbar from "@material-ui/core/Snackbar";
import Constants from "../common/Constants";
import * as _ from "lodash";

class ExecutiveDashboard extends React.Component {
    constructor(props) {
        super(props);
        let dashboardId = atob(this.props.match.params.dashboardId);
        let userId = atob(this.props.match.params.userId);
        this.state = {
            dashboardId: dashboardId,
            userId: userId,
            title: '',
            user: {},
            periods: [],
            settings: {},
            allPeriods: {},
            filterLabels: [],
            locationsStr: '',
            lastModification: '',
            isLoading: true,
            theme: {
                primary: '#000000',
                font: '#fff'
            },
            logo: {
                visible: false,
                url: ''
            },
            appliedFilters: [],
            isPrinting: false,
            periodsString: '',
            openFilters: false,
            valuesFilters: [],
            snackbar: {
                open: false,
                type: 'error',
                message: ''
            },
            isByLocation: false,
            activeLocation: 0,
            type: this.props.match.params.type,
            userBase64: this.props.match.params.userId
        };
    }

    // <editor-fold defaultstate="collapsed" desc="Init">
    getInitData = (periods) => {
        let url = "/executive-dashboard/init-data";
        let data = {
            dashboardId: this.state.dashboardId,
            userId: this.state.userId,
            periods: periods.toString()
        };
        ExecutiveDashboardServices.post(url, data)
            .then(response => {
                let periodsArray = [];
                let data = response.body.data;
                for (let i = 0; i < data.periods.length; i++) {
                    periodsArray.push(data.periods[i].id);
                }
                this.setState(
                    {
                        title: data.dashboard.name,
                        user: data.user,
                        periodsString: periodsArray.toString(),
                        periods: data.periods,
                        settings: data.dashboard.settings,
                        logo: data.dashboard.settings.logo,
                        allPeriods: data.allPeriods,
                        filterLabels: data.filterLabels,
                        locationsStr: data.locations,
                        lastModification: data.lastModification
                    }
                );
                if (this.state.valuesFilters.length === Constants.ZERO) {
                    this.handleFiltersReset()
                }
            })
            .catch(error => console.log(error))
            .finally(() => this.setState({isLoading: false}))
    };

    componentDidMount() {
        this.getInitData([]);
    }

    // </editor-fold>

    // <editor-fold defaultstate="collapsed" desc="Filters">
    handleOpenFilters = () => {
        this.setState({
            openFilters: true
        });
    };

    handleCloseFilters = () => {
        this.setState({
            openFilters: false
        });
    };

    handleApplyFilters = (selectedPeriods, appliedFilters, valuesFilters, locationId, locationsStr) => {
        if (selectedPeriods.length === Constants.ZERO) {
            this.handleOpenSnackbar('Selecciona al menos un período', Constants.SNACKBAR_ERROR_TYPE);
        } else {
            let isByLocation = valuesFilters[valuesFilters.length - 1].value !== Constants.EMPTY_STRING;
            this.setState({
                isLoading: true,
                openFilters: false
            }, () => {
                setTimeout(() => {
                    let periods = [];
                    selectedPeriods.map(id => {
                        let period = _.find(this.state.allPeriods, function (o) {
                            return o.id === id;
                        });
                        periods.push(period)
                    });
                    this.setState({
                        isByLocation: isByLocation,
                        activeLocation: locationId,
                        periods: periods,
                        periodsString: selectedPeriods.toString(),
                        appliedFilters: appliedFilters,
                        valuesFilters: valuesFilters,
                        isLoading: false,
                        locationsStr: locationsStr
                    });
                }, 300);
            });
        }
    };

    handleFiltersReset = () => {
        let valuesFilters = [];
        let labels = this.state.filterLabels;
        for (let y = Constants.ZERO; y < labels.length; y++) {
            let filterValue = {
                id: labels[y].sequence,
                value: Constants.DEFAULT_VALUE_FILTER
            };
            valuesFilters.push(filterValue);
        }
        this.setState({
            valuesFilters: valuesFilters,
            activeLocation: 0,
            isByLocation: false
        });

    };

    setValue = (id, value) => {
        let currentValues = this.state.valuesFilters;
        for (let i = 0; i < currentValues.length; i++) {
            if (Number.parseInt(currentValues[i].id) === Number.parseInt(id)) {
                currentValues[i].value = value;
                this.setState({
                    valuesFilters: currentValues
                });
                break;
            }
        }
    };

    // </editor-fold>

    // <editor-fold defaultstate="collapsed" desc="Snackbar">
    handleCloseSnackbar = () => {
        this.setState({
            snackbar: {
                open: false,
                type: 'error',
                message: ''
            }
        });
    };

    handleOpenSnackbar = (message, type) => {
        this.setState({
            snackbar: {
                open: true,
                type: type,
                message: message
            }
        });
    };

    // </editor-fold>

    render() {
        let colorTheme = '#000000';
        let colorFont = '#fff';

        if (!this.state.isLoading && this.state.settings.colors !== undefined) {
            colorTheme = this.state.settings.colors.table_header;
            colorFont = this.state.settings.colors.table_font_header;
        }

        const defaultTheme = createMuiTheme({
            palette: {
                primary: {
                    main: colorTheme,
                    contrastText: colorFont
                }
            }
        });

        const {breakpoints, typography: {pxToRem}} = defaultTheme;
        const theme = {
            ...defaultTheme,
            overrides: {
                MuiTypography: {
                    h5: {
                        fontSize: "18px",
                        [breakpoints.down("md")]: {
                            fontSize: "14px"
                        }
                    },
                    h6: {
                        fontSize: "15px",
                        [breakpoints.down("md")]: {
                            fontSize: "12px"
                        }
                    },
                    body1: {
                        fontSize: "14px",
                        [breakpoints.down("md")]: {
                            fontSize: "12px"
                        }
                    }
                }
            }
        };

        return (
            <MuiThemeProvider theme={theme}>
                <LoaderFullscreen active={this.state.isLoading}
                                  text={"Cargando..."}
                                  child={
                                      this.state.isLoading ? "" :
                                          (
                                              <React.Fragment>
                                                  <CssBaseline/>
                                                  <Container maxWidth="xl" component={'div'}>
                                                      <div className={"row"}>
                                                          <div className={"col-md-12"}>
                                                              <TitleBar
                                                                  logo={this.state.logo}
                                                                  title={this.state.title}
                                                                  colorTheme={colorTheme}
                                                                  colorFont={colorFont}
                                                                  user={this.state.user}
                                                                  handleOpenFilters={this.handleOpenFilters}
                                                                  appliedFilters={this.state.appliedFilters}
                                                                  isPrinting={this.state.isPrinting}
                                                                  type={this.state.type}
                                                                  userBase64={this.state.userBase64}
                                                              />
                                                          </div>
                                                      </div>
                                                      <div className={"row pt-4"}>
                                                          <div className={"col-md-12"}>
                                                              <GeneralResults
                                                                  dashboardId={this.state.dashboardId}
                                                                  dashboardName={this.state.title}
                                                                  periods={this.state.periods}
                                                                  semaphore={this.state.settings.semaphore}
                                                                  decimals={this.state.settings.decimals}
                                                                  typeCalculation={this.state.settings.typeCalculation}
                                                                  lastModification={this.state.lastModification}
                                                                  locationsStr={this.state.locationsStr}
                                                              />
                                                          </div>
                                                      </div>

                                                      <div className={"row"}>
                                                          <div className={"col-md-6"}>
                                                              <Progress
                                                                  dashboardId={this.state.dashboardId}
                                                                  periods={this.state.periods}
                                                                  periodsString={this.state.periodsString}
                                                                  userId={this.state.userId}
                                                                  colorTheme={colorTheme}
                                                              />
                                                          </div>
                                                          <div className={"col-md-6"}>
                                                              <Rank
                                                                  dashboardId={this.state.dashboardId}
                                                                  periods={this.state.periods}
                                                                  semaphore={this.state.settings.semaphore}
                                                                  decimals={this.state.settings.decimals}
                                                                  typeCalculation={this.state.settings.typeCalculation}
                                                                  userId={this.state.userId}
                                                                  dashboardName={this.state.title}
                                                              />
                                                          </div>
                                                      </div>

                                                      <div className={"row"}>
                                                          < div className={"col-md-12"}>
                                                              {
                                                                  this.state.settings.footer.showFilterGraphs ? (
                                                                      <ScoredByFilter
                                                                          dashboardId={this.state.dashboardId}
                                                                          periods={this.state.periods}
                                                                          appliedFilters={this.state.appliedFilters}
                                                                          userId={this.state.userId}
                                                                          periodsString={this.state.periodsString}
                                                                          locationsStr={this.state.locationsStr}
                                                                          colorTheme={colorTheme}
                                                                          decimals={this.state.settings.decimals}
                                                                          typeCalculation={this.state.settings.typeCalculation}
                                                                          semaphore={this.state.settings.semaphore}
                                                                      />
                                                                  ) : ''
                                                              }
                                                          </div>
                                                      </div>

                                                      <div className={"row"}>
                                                          <div className={"col-md-12"}>
                                                              <Filters
                                                                  openFilters={this.state.openFilters}
                                                                  dashboardId={this.state.dashboardId}
                                                                  handleCloseFilters={this.handleCloseFilters}
                                                                  periods={this.state.periods}
                                                                  allPeriods={this.state.allPeriods}
                                                                  periodsString={this.state.periodsString}
                                                                  handleApplyFilters={this.handleApplyFilters}
                                                                  filterLabels={this.state.filterLabels}
                                                                  valuesFilters={this.state.valuesFilters}
                                                                  handleFiltersReset={this.handleFiltersReset}
                                                                  setValue={this.setValue}
                                                                  userId={this.state.userId}
                                                              />
                                                          </div>
                                                      </div>
                                                      <div className={'float-right p-2'}>
                                                          <label style={{
                                                              fontWeight: '600',
                                                              fontSize: '10px',
                                                              padding: '5px'
                                                          }}>Powered by </label>
                                                          <img style={{width: "100px"}}
                                                               src={'https://elasticbeanstalk-us-east-1-460503004594.s3.amazonaws.com/upaxer/administrator/resources/logoUpaxer.png'}
                                                               alt={"Logo"}/>

                                                      </div>
                                                  </Container>
                                              </React.Fragment>
                                          )
                                  }
                />
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={this.state.snackbar.open}
                    autoHideDuration={6000}
                    onClose={this.handleCloseSnackbar}
                >
                    <SnackbarContentWrapper
                        onClose={this.handleCloseSnackbar}
                        variant={this.state.snackbar.type}
                        message={this.state.snackbar.message}
                    />
                </Snackbar>
            </MuiThemeProvider>
        )
    }


}

export default ExecutiveDashboard;
