import React from 'react'
import {ExecutiveDashboardServices} from "../../api/service";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Card from "../common/Card";
import Constants from "../../common/Constants";
import * as _ from "lodash";
import ColumnAndLineChart from "../common/chart/ColumnAndLine";

class ScoredByFilter extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            data: [],
            dashboards: {}
        };
    }

    getScoreByFilter = () => {
        let url = "/executive-dashboard/score/filter";
        let data = {
            dashboardId: this.props.dashboardId,
            periodsObj: this.props.periods,
            appliedFilters: this.props.appliedFilters,
            userId: this.props.userId,
            locationsStr: this.props.locationsStr,
            periods: this.props.periodsString,
            decimals: this.props.decimals,
            typeCalculation: this.props.typeCalculation
        };
        ExecutiveDashboardServices.post(url, data)
            .then(response => {
                let data = response.body.data;
                this.setState({
                    data: data.filters,
                    dashboards: data.dashboards
                })
            })
            .catch(error => console.log(error))
            .finally(() => this.setState({isLoading: false}))
    };

    componentDidMount() {
        this.getScoreByFilter();
    }

    render() {
        let symbol = this.props.typeCalculation === Constants.TYPE_CALCULATION_DECIMAL ? "" : "%";
        return (
            <Card
                cardHeader={
                    {
                        title: 'Calificaciones por filtro',
                        subheader: '',
                        action: (
                            <CircularProgress color={"primary"} hidden={!this.state.isLoading}/>
                        )
                    }
                }
                cardContent={
                    this.state.isLoading ? "" :
                        <div>
                            {
                                _.orderBy(this.state.data, ['sequence'], ['asc']).map(d => {
                                    return (
                                        <div key={'div-f' + d.sequence}>
                                            <Typography variant="h6" gutterBottom className={'text-center font-weight-bold'} >
                                                {d.name}
                                            </Typography>
                                            {
                                                _.orderBy(d.periods, ['id'], ['desc']).map((period, index) =>{
                                                    return(
                                                        <ColumnAndLineChart
                                                            key={'chart-' + period.id}
                                                            title={period.year + ' | ' + period.name}
                                                            chartId={d.sequence + '-' + period.id+ '-' + index}
                                                            data={period.payload}
                                                            symbol={symbol}
                                                            semaphore={this.props.semaphore}
                                                            typeCalculation={this.props.typeCalculation}
                                                            colorTheme={this.props.colorTheme}
                                                            dashboards={this.state.dashboards}
                                                        />
                                                    )
                                                })
                                            }

                                        </div>

                                    );
                                })
                            }
                        </div>
                }
            />
        )
    }

}

export default ScoredByFilter;
