import React from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import ExecutiveDashboard from "./containers/ExecutiveDashboard";
import Survey from "./containers/Survey";

function App() {
  return (
      <Router>
          <Switch>
            <Route path="/executivedashboard/:dashboardId/:userId/:type" component={ExecutiveDashboard}/>
              <Route path="/survey" component={Survey}/>
          </Switch>
      </Router>
  );
}

export default App;
