import React from 'react';
import {Card as MaterialCard} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";

function Card({cardHeader, cardContent, cardActions}) {
    return (
        <MaterialCard square={true}
              variant={"outlined"}
              style={{overflow: "inherit"}}
              className={"mb-3 primary card "}
              elevation={1}
        >
            <CardHeader
                {...cardHeader}
            />
            <CardContent component={'div'}>
                {cardContent}
            </CardContent>
            <CardActions>
                {cardActions}
            </CardActions>
        </MaterialCard>
    );
}

export default Card;
