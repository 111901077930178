import React from 'react';
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import SurveyTitleBar from "../components/survey/TitleBar";
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core";
import SurveyForm from "../components/survey/Form";

class Survey extends React.Component {
    constructor(props) {
        super(props);

    }

    render() {
        const theme = createMuiTheme({
            palette: {
                primary: {
                    main: '#ff995b',
                    contrastText: '#ffffff'
                }
            }
        });
        return (
            <MuiThemeProvider theme={theme}>
                <SurveyTitleBar/>
                <SurveyForm/>
            </MuiThemeProvider>
        );
    }

}

export default Survey;