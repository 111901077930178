import React from 'react';
import IconButton from "@material-ui/core/IconButton";
import {Tooltip, withStyles} from "@material-ui/core";
import CustomDrawer from "../common/Drawer";
import Divider from "@material-ui/core/Divider";
import CloseIcon from '@material-ui/icons/Close';
import 'react-widgets/dist/css/react-widgets.css';
import {Multiselect} from 'react-widgets'
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import Filter from "../Filter";
import {ExecutiveDashboardServices} from "../../api/service";
import * as _ from "lodash";
import Util from "../../common/Util";
import Constants from "../../common/Constants";
import ClearAllIcon from '@material-ui/icons/ClearAll';
import SearchIcon from '@material-ui/icons/Search';
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import FilterListIcon from '@material-ui/icons/FilterList';
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = theme => ({
    primary: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main
    },
    button: {
        margin: theme.spacing(1),
        [theme.breakpoints.down("sm")]: {
            minWidth: 32,
            paddingLeft: 8,
            paddingRight: 8,
            "& .MuiButton-startIcon": {
                margin: 0
            }
        }
    },
    buttonText: {
        [theme.breakpoints.down("sm")]: {
            display: "none"
        }
    }
});

class Filters extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            allPeriods: this.props.allPeriods,
            selectedPeriods: this.props.periods,
            filters: [],
            allFilters: [],
            appliedFilters: []
        }
    }

    getFilterValues = () => {
        let url = "dashboard/filter";
        let data = {
            dashboardId: this.props.dashboardId,
            periods: this.props.periodsString,
            userId: this.props.userId
        };
        ExecutiveDashboardServices.post(url, data)
            .then(response => {
                this.setState({
                    allFilters: response.body.data
                });
                this.initialStateFilters();
            })
            .catch(error => console.log(error))
            .finally(() => this.setState({isLoading: false}))
    };

    componentDidMount() {
        this.getFilterValues();
    }

    initialStateFilters = () => {
        let allFilters = this.state.allFilters;
        let filters = [];
        let labels = this.props.filterLabels;
        for (let i = 0; i < labels.length; i++) {
            let fieldName = 'filter' + labels[i].sequence;
            let uniqItems = _.uniqBy(allFilters, fieldName);
            let valuesFilter = Util.getFilterValues(uniqItems, fieldName);
            let filter = {
                id: labels[i].sequence,
                name: labels[i].name,
                values: valuesFilter
            };
            filters.push(filter);
        }

        this.setState({
            filters: filters
        });
    };

    handleFiltersReset = () => {
        this.initialStateFilters();
        this.props.handleFiltersReset();
    };

    updateNextFilters = (id, value) => {
        let currentFilters = this.state.filters;
        let field = 'filter' + id;
        let filters = _.filter(this.state.allFilters, function (o) {
            return o[field] === value;
        });
        if (id > 1) {
            for (let z = id - 1; z > 0; z--) {
                let previousValue = Util.getCurrentFilterValue(z, this.props.valuesFilters);
                if (previousValue !== Constants.DEFAULT_VALUE_FILTER) {
                    let previousField = 'filter' + z;
                    filters = _.filter(filters, function (o) {
                        return o[previousField] === previousValue;
                    });
                }
            }
        }
        let nextIdx = id + 1;
        for (nextIdx; nextIdx <= currentFilters.length; nextIdx++) {
            let fieldName = 'filter' + nextIdx;
            let uniqItems = _.uniqBy(filters, fieldName);
            let valueNextFilter = Util.getCurrentFilterValue(nextIdx, this.props.valuesFilters);
            let valuesFilter = Util.getFilterValues(uniqItems, fieldName);
            if (valuesFilter.length === 1) {
                valueNextFilter = valuesFilter[0];
            }
            this.props.setValue(nextIdx, valueNextFilter);
            currentFilters = Util.updateFilterValues(nextIdx, currentFilters, valuesFilter);
            if (valueNextFilter !== Constants.DEFAULT_VALUE_FILTER) {
                filters = _.filter(filters, function (o) {
                    return o[fieldName] === valueNextFilter;
                });
            }
        }
        this.setState({
            filters: currentFilters
        });
    };

    updatePreviousFilters = (id, value) => {
        let field = 'filter' + id;
        let filters = _.filter(this.state.allFilters, function (o) {
            return o[field] === value;
        });
        let currentFilters = this.state.filters;
        let previousIdx = id - 1;
        for (previousIdx; previousIdx > 0; previousIdx--) {
            let previousField = 'filter' + previousIdx;
            let uniqFilters = _.uniqBy(filters, previousField);
            if (uniqFilters.length > 1) {
                let newValues = Util.getFilterValues(uniqFilters, previousField);
                let currentValue = Util.getCurrentFilterValue(previousIdx, this.props.valuesFilters);
                if (!newValues.includes(currentValue)) {
                    this.props.setValue(previousIdx, Constants.DEFAULT_VALUE_FILTER);
                }
                currentFilters = Util.updateFilterValues(previousIdx, currentFilters, newValues);

            } else {
                let previousValue = [uniqFilters[Constants.ZERO][previousField]];
                currentFilters = Util.updateFilterValues(previousIdx, currentFilters, previousValue);
                this.props.setValue(previousIdx, previousValue[Constants.ZERO]);
            }
        }

        this.setState({
            filters: currentFilters
        });
    };

    handleChangePeriods = value => {
        this.setState({
            selectedPeriods: value
        });
    };

    handleApplyFilters = () => {
        let selected = [];
        for (let i = 0; i < this.state.selectedPeriods.length; i++) {
            selected.push(this.state.selectedPeriods[i].id);
        }
        let appliedFilters = [];
        _.forEach(this.props.valuesFilters, function (filter) {
            if (filter.value !== Constants.DEFAULT_VALUE_FILTER) {
                appliedFilters.push(filter);
            }
        });

        let locationId = Constants.ZERO;
        if (appliedFilters.length === this.props.valuesFilters.length) {
            let lastIndex = appliedFilters.length - 1;
            let field = 'filter' + (appliedFilters.length);
            let value = appliedFilters[lastIndex].value;
            let filter = _.find(this.state.allFilters, function (o) {
                return o[field] === value;
            });
            locationId = filter.location;
        }


        let all = this.state.allFilters;
        _.forEach(this.props.valuesFilters, function (appliedFilter) {
            if (appliedFilter.value !== Constants.EMPTY_STRING) {
                all = _.filter(all, function (o) {
                    return o['filter' + appliedFilter.id] === appliedFilter.value;
                });
            }
        });

        let locationsStr = '';
        _.forEach(all, function (filtered) {
            locationsStr = locationsStr + filtered.location + ','
        });

        this.props.handleApplyFilters(selected, appliedFilters, this.props.valuesFilters, locationId, locationsStr);
    };

    handleOnSelectFilter = (id, value) => {
        this.props.setValue(id, value);
        this.updatePreviousFilters(id, value);
        this.updateNextFilters(id, value);
    };

    handleOnChangeFilter = (id, value) => {
        this.props.setValue(id, value);
    };

    render() {
        const {classes} = this.props;
        let TagPeriod = ({item}) => (
            <span>{item.year + ' | ' + item.name}</span>
        );
        return (
            <CustomDrawer
                open={this.props.openFilters}
                handleClose={this.props.handleCloseFilters}
                side={"right"}
                width={"500px"}
                drawerContent={
                    <div>
                        <div>
                            <List dense={true}>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar className={classes.primary}>
                                            <FilterListIcon/>
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={<h4>Filtros</h4>}
                                        secondary={"Selecciona los filtros a aplicar en tu dashboard"}
                                    />
                                    <ListItemSecondaryAction>
                                        <Tooltip title={"Cerrar"}>
                                            <IconButton edge="end" aria-label="Cerrar" color={"primary"}
                                                        onClick={this.props.handleCloseFilters}>
                                                <CloseIcon/>
                                            </IconButton>
                                        </Tooltip>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </List>
                        </div>
                        <Divider variant={"fullWidth"}/>
                        <div style={{padding: "20px"}}>
                            <div>
                                <InputLabel htmlFor="periods" shrink={true} style={{fontWeight: "bold"}}
                                            required={true}>Períodos</InputLabel>
                                <Multiselect
                                    id={"periods"}
                                    data={this.state.allPeriods}
                                    valueField='id'
                                    textField='name'
                                    groupBy='year'
                                    tagComponent={TagPeriod}
                                    defaultValue={this.props.periods}
                                    onChange={value => this.handleChangePeriods(value)}
                                    messages={{
                                        emptyList: 'Sin opciones para seleccionar'
                                    }}
                                />
                            </div>
                            {
                                this.state.isLoading ?
                                    <div className={"text-center pt-4"}><CircularProgress color={"primary"}/></div> :
                                    this.state.filters.map((filter, index) => {
                                        return (
                                            <Filter
                                                key={"obj-filter-" + index}
                                                filter={filter}
                                                value={this.props.valuesFilters[index].value}
                                                handleOnChangeFilter={this.handleOnChangeFilter}
                                                handleOnSelectFilter={this.handleOnSelectFilter}

                                            />
                                        )
                                    })
                            }
                            <div className={"pt-5 float-left"}>
                                <Button variant="contained"
                                        color="default"
                                        startIcon={<ClearAllIcon/>}
                                        onClick={this.handleFiltersReset}
                                        hidden={this.props.filterLabels.length === Constants.ZERO}
                                        className={classes.button}
                                >
                                    <span className={classes.buttonText}>{"Limpiar"}</span>
                                </Button>
                            </div>

                            <div className={"pt-5 float-right"}>
                                <Button variant="contained"
                                        color="primary"
                                        startIcon={<SearchIcon/>}
                                        onClick={this.handleApplyFilters}
                                        className={classes.button}
                                >
                                    <span className={classes.buttonText}>{"Buscar"}</span>
                                </Button>
                            </div>
                        </div>
                    </div>
                }
            />
        );
    }
}

export default withStyles(useStyles)(Filters);
