import Constants from "./Constants";
import * as _ from "lodash";

function getFilterValues(filters, fieldName) {
    let valuesFilter = [];
    for (let x = 0; x < filters.length; x++) {
        valuesFilter.push(filters[x][fieldName]);
    }
    return _.orderBy(valuesFilter);
}

function updateFilterValues(idFilterToUpdate, currentFilters, newValues) {
    let updateFilters = currentFilters;
    for (let y = 0; y < updateFilters.length; y++) {
        if (updateFilters[y].id === idFilterToUpdate) {
            updateFilters[y].values = _.orderBy(newValues);
            break;
        }
    }
    return updateFilters;
}

function getCurrentFilterValue(id, filterValues) {
    let filterValue = _.find(filterValues, function (o) {
        return o.id === id;
    });
    return filterValue.value;
}

function getColorFromSemaphore(score, semaphore, typeCalculation) {
    let isDecimal = typeCalculation === Constants.TYPE_CALCULATION_DECIMAL;
    let color = '#ff995b';
    for (let i = Constants.ZERO; i < semaphore.length; i++) {
        let rank = semaphore[i];
        let minimumValue = isDecimal ? rank.min : rank.min * 100;
        let maximumValue = isDecimal ? rank.max : rank.max * 100;
        if (score >= minimumValue && score <= maximumValue) {
            color = rank.color_background;
            break;
        }
    }

    return color;
}

function getSymbol(typeCalculation) {
    return typeCalculation === Constants.TYPE_CALCULATION_DECIMAL ? "" : "%";
}

export default {getFilterValues, updateFilterValues, getCurrentFilterValue, getColorFromSemaphore, getSymbol};
