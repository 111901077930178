import React from 'react';
import SolidGaugeChart from "../common/chart/SolidGauge";
import {ExecutiveDashboardServices} from "../../api/service";
import * as _ from "lodash";

class GeneralResult extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            data: []
        }
    };

    getScoreByPeriod = () => {
        let url = "/executive-dashboard/score/period";
        let data = {
            dashboardId: this.props.dashboardId,
            dashboardName: this.props.dashboardName,
            periodId: this.props.period.id,
            semaphore: this.props.semaphore,
            decimals: this.props.decimals,
            typeCalculation: this.props.typeCalculation,
            locationsStr: this.props.locationsStr
        };
        ExecutiveDashboardServices.post(url, data)
            .then(response => {
                let data = response.body.data;
                data =  _.orderBy(data, ['dashboardId'], ['desc'])
                this.setState(
                    {
                        data: data
                    }
                );
                if (this.props.toDisableLoader) {
                    this.props.handleDisableLoader();
                }
            })
            .catch(error => console.log(error))
            .finally(() => this.setState({isLoading: false}))
    };

    componentDidMount() {
        this.getScoreByPeriod();
    }

    render() {
        return (
            this.state.isLoading ? '' : (
                <SolidGaugeChart
                    data={this.state.data}
                    period={this.props.period}
                    semaphore={this.props.semaphore}
                    typeCalculation={this.props.typeCalculation}
                />
            )
        );
    }
}

export default GeneralResult;
