import React from 'react';
import {Combobox} from 'react-widgets';
import 'react-widgets/dist/css/react-widgets.css';
import InputLabel from "@material-ui/core/InputLabel";

class Filter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value,
            messages: {
                openCombobox: 'Desplegar',
                emptyList: 'Sin datos para seleccionar',
                emptyFilter: 'No hay coincidencias'
            }
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            value: nextProps.value
        });
    }

    handleChange = (id, value) => {
        this.setState({
            value: value
        });
        this.props.handleOnChangeFilter(id, value);
    };

    render() {
        return (
            <div className={"pt-3"} key={"div-filer-" + this.props.filter.id}>
                <InputLabel htmlFor={"filter" + this.props.filter.id} shrink={true}
                            style={{fontWeight: "bold"}}>{this.props.filter.name}</InputLabel>
                <Combobox
                    key={"filter" + this.props.filter.id}
                    data={this.props.filter.values}
                    caseSensitive={false}
                    minLength={3}
                    value={this.state.value}
                    defaultValue={this.state.value}
                    filter={'contains'}
                    onChange={value => this.handleChange(this.props.filter.id, value)}
                    onSelect={value => this.props.handleOnSelectFilter(this.props.filter.id, value)}
                    messages={this.state.messages}
                />
            </div>

        );
    }


}

export default Filter;
