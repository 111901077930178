/* Imports */
import React from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import Util from "../../../../common/Util";

/* Chart code */
// Themes begin
am4core.useTheme(am4themes_animated);

class ColumnAndLineChart extends React.Component{
    componentDidMount() {
        // Create chart instance
        let chart = am4core.create("column-chart-div-" + this.props.chartId, am4charts.XYChart);

// Data for both series
        let data = this.props.data;

        let title = chart.titles.create();
        title.text = this.props.title;
        title.fontSize = 15;
        title.marginBottom = 10;
        /* Create axes */
        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "element";
        categoryAxis.renderer.minGridDistance = 30;

        /* Create value axis */
        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
        valueAxis.max = 110;
        valueAxis.strictMinMax = true;

        /* Create series */
        let columnSeries = chart.series.push(new am4charts.ColumnSeries());
        columnSeries.name = this.props.dashboards.primary === undefined ? "" : this.props.dashboards.primary;
        columnSeries.dataFields.valueY = "primary";
        columnSeries.dataFields.categoryX = "element";

        columnSeries.columns.template.tooltipText = "[#fff font-size: 15px]{name}\n{categoryX}\n[/][#fff font-size: 20px]{valueY}" + this.props.symbol + "[/] [#fff]{additional}[/]";
        columnSeries.columns.template.propertyFields.fillOpacity = "fillOpacity";
        columnSeries.columns.template.propertyFields.stroke = "stroke";
        columnSeries.columns.template.propertyFields.strokeWidth = "strokeWidth";
        columnSeries.columns.template.propertyFields.strokeDasharray = "columnDash";
        columnSeries.tooltip.label.textAlign = "middle";
        let semaphore = this.props.semaphore;
        let typeCalculation = this.props.typeCalculation;
        columnSeries.columns.template.events.once("inited", function (event) {
            let color = Util.getColorFromSemaphore(event.target.dataItem.valueY, semaphore, typeCalculation);
            event.target.fill = am4core.color(color);
            event.target.stroke = am4core.color(color);
        });

        /* Create series */
        let columnSeries2 = chart.series.push(new am4charts.ColumnSeries());
        columnSeries2.name = this.props.dashboards.secondary === undefined ? "" : this.props.dashboards.secondary;
        columnSeries2.dataFields.valueY = "secondary";
        columnSeries2.dataFields.categoryX = "element";
        columnSeries2.columns.template.tooltipText = "[#fff font-size: 15px]{name}\n{categoryX}\n[/][#fff font-size: 20px]{valueY}" + this.props.symbol + "[/] [#fff]{additional}[/]";
        columnSeries2.columns.template.propertyFields.fillOpacity = "fillOpacity";
        columnSeries2.columns.template.propertyFields.stroke = "stroke";
        columnSeries2.columns.template.propertyFields.strokeWidth = "strokeWidth";
        columnSeries2.columns.template.propertyFields.strokeDasharray = "columnDash";
        columnSeries2.tooltip.label.textAlign = "middle";
        columnSeries2.columns.template.events.once("inited", function (event) {
            let color = Util.getColorFromSemaphore(event.target.dataItem.valueY, semaphore, typeCalculation);
            event.target.fill = am4core.color(color);
            event.target.stroke = am4core.color(color);
        });

        let lineSeries = chart.series.push(new am4charts.LineSeries());
        lineSeries.name = this.props.dashboards.general === undefined ? "" : this.props.dashboards.general;
        lineSeries.dataFields.valueY = "general";
        lineSeries.dataFields.categoryX = "element";

        lineSeries.stroke = am4core.color(this.props.colorTheme);
        lineSeries.strokeWidth = 3;
        lineSeries.propertyFields.strokeDasharray = "lineDash";
        lineSeries.tooltip.label.textAlign = "middle";

        let bullet = lineSeries.bullets.push(new am4charts.Bullet());
        bullet.fill = am4core.color(this.props.colorTheme); // tooltips grab fill from parent by default
        bullet.tooltipText = "[#fff font-size: 15px]{name}\n{categoryX}\n[/][#fff font-size: 20px]{valueY}" + this.props.symbol + "[/] [#fff]{additional}[/]";
        let circle = bullet.createChild(am4core.Circle);
        circle.radius = 4;
        circle.fill = am4core.color("#fff");
        circle.strokeWidth = 3;
        chart.legend = new am4charts.Legend();
        chart.legend.position = "bottom";
        chart.data = data;
    }

    render() {
        return (
            <div id={"column-chart-div-" + this.props.chartId} style={{height: '300px', width: "100%"}}/>

        );
    }
}

export default ColumnAndLineChart;
