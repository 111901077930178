import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Tooltip from "@material-ui/core/Tooltip";
import FilterListIcon from '@material-ui/icons/FilterList';
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";

const useStyles = theme => ({
    primary: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main
    },
    grow: {
        flexGrow: 1,
    },
    title: {
        [theme.breakpoints.up('sm')]: {
            fontSize: '18px',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px'
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'none',
        [theme.breakpoints.down('md')]: {
            display: 'flex',
        },
    },
    breadcrumbs: {
        [theme.breakpoints.up('sm')]: {
            fontSize: '14px',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '10px'
        },
    },
    menuText: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '12px'
        },
    },
});

class TitleBar extends React.Component {

    render() {
        const {classes} = this.props;
        const logo = this.props.logo;
        const title = this.props.title;
        const colorFont = this.props.colorFont;
        const appliedFilters = this.props.appliedFilters;
        const user = this.props.user;
        return (
            <div className={classes.grow}>
                <AppBar position="fixed" color={"primary"}>
                    <Toolbar>
                        <div className={classes.sectionDesktop}>
                            <div className={"float-left"}
                                 style={{height: '64px', paddingTop: '7px', position: 'absolute'}}
                                 hidden={this.props.isPrinting}>
                                <Tooltip title={"Regresar"}>
                                    <IconButton aria-label="regresar"
                                                href={parseInt(this.props.type) === 1 ? 'https://administrador.upaxer.com/DashboardFranquiciasGuardado.jsp' : 'https://dashboard.upaxer.com/dashboard-list/' + this.props.userBase64}
                                                target={'_self'}>
                                        <ArrowBackIosOutlinedIcon/>
                                    </IconButton>
                                </Tooltip>
                            </div>
                            <div style={{
                                borderRadius: '0px 0px 60px 0px',
                                backgroundColor: '#fff',
                                width: '260px',
                                height: '64px',
                                marginTop: '-1px',
                                marginRight: '15px'
                            }} className={"text-center"}>
                                {
                                    logo.visible ?
                                        <div color="inherit" aria-label="Logo" style={{marginTop: '-13px !important'}}>
                                            <img style={{width: "auto", height: "64px"}} src={logo.url} alt={"Logo"}/>
                                        </div>
                                        : ""
                                }
                            </div>
                        </div>

                        <div className={"pl-2"}>
                            <Typography variant="h6" component={'div'} className={classes.title}>
                                {title}
                            </Typography>
                            <div>
                                <Breadcrumbs separator={<NavigateNextIcon fontSize="small"/>} aria-label="breadcrumb"
                                             style={{color: colorFont}} maxItems={2}>
                                    {
                                        appliedFilters.map(function (filter, index) {
                                            return (
                                                <Typography key={'mp-f' + index} className={classes.breadcrumbs}>
                                                    {filter.value}
                                                </Typography>
                                            );
                                        })
                                    }
                                </Breadcrumbs>
                            </div>
                        </div>

                        <div className={classes.grow}/>
                        <div className={classes.sectionDesktop}>
                            <Tooltip title="Filtros">
                                <IconButton aria-label="" color="inherit" onClick={this.props.handleOpenFilters}>
                                    <FilterListIcon/>
                                </IconButton>
                            </Tooltip>
                            <div>
                                <ListItem dense={true} style={{fontSize: '5px'}}>
                                    <ListItemText
                                        primary={<span style={{float: 'right'}}>{user.fullName}</span>}
                                        secondary={<span style={{
                                            color: colorFont,
                                            float: 'right'
                                        }}>{user.user}</span>}/>
                                </ListItem>
                            </div>
                        </div>
                    </Toolbar>
                </AppBar>
            </div>
        );
    }

}

export default withStyles(useStyles)(TitleBar);
