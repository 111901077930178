import React from 'react'
import Card from "../common/Card";
import LayeredColumnChart from "../common/chart/LayeredColumn";
import {ExecutiveDashboardServices} from "../../api/service";
import CircularProgress from "@material-ui/core/CircularProgress";

class Progress extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            title: 'Avance de levantamiento',
            subheader: 'Muestra el avance de campo',
            chart: {
                data: [],
                category: '',
                series: [],
                colors: []
            }
        };
    }

    getProgress = () => {
        let url = "/executive-dashboard/progress";
        let data = {
            dashboardId: this.props.dashboardId,
            periods: this.props.periodsString,
            userId: this.props.userId
        };
        ExecutiveDashboardServices.post(url, data)
            .then(response => {
                this.setState(
                    {
                        chart: {
                            data: response.body.data,
                            category: "periodName",
                            series: ['total', 'progress'],
                            colors: [this.props.colorTheme, this.props.colorTheme],
                            tooltip: ['Total de sucursales', 'Sucursales visitadas']
                        }
                    }
                );
            })
            .catch(error => console.log(error))
            .finally(() => this.setState({isLoading: false}))
    };

    componentDidMount() {
        this.getProgress();
    }

    render() {
        return (
            <Card
                cardHeader={
                    {
                        title: this.state.title,
                        subheader: this.state.subheader,
                        action: (
                            <CircularProgress color={"primary"} hidden={!this.state.isLoading}/>
                        )
                    }
                }
                cardContent={
                    this.state.isLoading ? <div style={{height:"300px"}}/> :
                    <LayeredColumnChart
                        chartId={'progress'}
                        chart={this.state.chart}
                        height={"300px"}
                    />
                }
            />
        );
    }
}

export default Progress;
