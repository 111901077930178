import {ExecutiveDashboardAPI} from './config';

export const ExecutiveDashboardServices = {
    post: (url, data) => new Promise(
        (resolve, reject) => {
            ExecutiveDashboardAPI.post(url, data)
                .then(
                    res => res.data
                )
                .then(
                    data => resolve(data)
                )
                .catch(
                    err => reject(err)
                )
        }
    )
};

export default {ExecutiveDashboardServices};
