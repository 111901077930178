import React from 'react'
import Card from "../common/Card";
import SimplePieChart from "../common/chart/SimplePie";
import {ExecutiveDashboardServices} from "../../api/service";
import * as _ from "lodash";
import CircularProgress from "@material-ui/core/CircularProgress";

class Rank extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            title: "Sucursales",
            subheader: "Muestra el impacto de resultados por tienda",
            chart: []
        };
    }

    getResultsPerRank = () => {
        let url = "/executive-dashboard/rank/location";
        let maxPeriod = _.maxBy(this.props.periods, 'id');
        let data = {
            dashboardId: this.props.dashboardId,
            periodId: maxPeriod.id,
            semaphore: this.props.semaphore,
            typeCalculation: this.props.typeCalculation,
            decimals: this.props.decimals,
            userId: this.props.userId,
            dashboardName: this.props.dashboardName
        };
        ExecutiveDashboardServices.post(url, data)
            .then(response => {
                let data = _.orderBy(response.body.data, ['id'], ['asc']);
                this.setState(
                    {
                        charts: data
                    }
                );
            })
            .catch(error => console.log(error))
            .finally(() => this.setState({isLoading: false}))
    };

    componentDidMount() {
        this.getResultsPerRank();
    }

    render() {
        return (
            <Card
                cardHeader={
                    {
                        title: this.state.title,
                        subheader: this.state.subheader,
                        action: (
                            <CircularProgress color={"primary"} hidden={!this.state.isLoading}/>
                        )
                    }
                }
                cardContent={
                    this.state.isLoading ? <div style={{height: "300px"}}/> :
                        this.state.charts.map((chart, index) => {
                            return (
                                <SimplePieChart
                                    key={"rnkk-" + index}
                                    chartId={"rnk-" + index}
                                    chart={chart}
                                    height={"200px"}
                                />)
                        })

                }
            />
        );
    }
}

export default Rank;
