import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import Hidden from "@material-ui/core/Hidden";
import {makeStyles} from '@material-ui/core/styles';

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: 500,
            flexShrink: 0,
        },
        [theme.breakpoints.down('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        }
    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        [theme.breakpoints.up('sm')]: {
            width: 500
        },
        [theme.breakpoints.down('sm')]: {
            width: drawerWidth
        }
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
}));

function CustomDrawer(props) {
    const {window} = props;
    const classes = useStyles();
    const container = window !== undefined ? () => window().document.body : undefined;
    return (
        <div>
            <Hidden smUp implementation="css">
                <Drawer
                    container={container}
                    variant="temporary"
                    anchor={props.side}
                    open={props.open}
                    onClose={props.handleClose}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                >
                    {props.drawerContent}
                </Drawer>
            </Hidden>
            <Hidden xsDown implementation="css">
                <Drawer anchor={props.side}
                        open={props.open}
                        onClose={props.handleClose}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                >
                    {props.drawerContent}
                </Drawer>
            </Hidden>
        </div>

    );
}

export default CustomDrawer;
