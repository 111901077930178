import React from "react";
import LoadingScreen from 'react-loading-screen'

export default function LoaderFullscreen({active, text, child}) {
    return (
        <LoadingScreen
            loading={active}
            bgColor='#f1f1f1'
            spinnerColor='#ff995b'
            textColor='#a6a6a6'
            logoSrc='https://elasticbeanstalk-us-east-1-460503004594.s3.amazonaws.com/upaxer/administrator/resources/logoUpaxer.png'
            text={text}
        >
            {child}
        </LoadingScreen>
    )
}
