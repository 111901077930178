import React from 'react';
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Card from "../../common/Card";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {GoogleReCaptchaProvider, GoogleReCaptcha} from "react-google-recaptcha-v3";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContentWrapper from "../../common/Snackbar";
import Constants from "../../../common/Constants";
import {ExecutiveDashboardServices} from "../../../api/service";
import CircularProgress from "@material-ui/core/CircularProgress";
import {withStyles} from "@material-ui/core/styles";

const useStyles = theme => ({
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
});

class SurveyForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            token: '',
            snackbar: {
                open: false,
                type: 'error',
                message: ''
            },
            loading: false
        };
    }

    // <editor-fold defaultstate="collapsed" desc="Snackbar">
    handleCloseSnackbar = () => {
        this.setState({
            snackbar: {
                open: false,
                type: 'error',
                message: ''
            }
        });
    };

    handleOpenSnackbar = (message, type) => {
        this.setState({
            snackbar: {
                open: true,
                type: type,
                message: message
            }
        });
    };

    // </editor-fold>

    handleOnChange = (event) => {
        let value = event.target.value;
        this.setState({
            email: value
        });
    };

    verifyCallback = (token) => {
        this.setState({
            token: token
        });
    }

    validateForm = () => {
        let verified = this.state.token !== '';

        if (verified) {
            if (this.state.email !== '') {
                if (this.state.email.indexOf('@') < 0) {
                    this.handleOpenSnackbar('Correo electrónico no valido: ej. usuario@gmail.com', Constants.SNACKBAR_ERROR_TYPE);
                } else {
                    this.sendForm();
                }
            } else {
                this.handleOpenSnackbar('Ingresa tu correo electrónico', Constants.SNACKBAR_ERROR_TYPE);
            }
        } else {
            this.handleOpenSnackbar('No se ha podido validar el sitio', Constants.SNACKBAR_ERROR_TYPE);
        }
    };

    sendForm = () => {
        this.setState({
            loading: true
        })
        let url = "/survey/url";
        let data = {
            email: this.state.email,
            token: this.state.token
        };
        ExecutiveDashboardServices.post(url, data)
            .then(response => {
                let code = response.status;
                if (code === 200){
                    const link = document.createElement('a');
                    link.href = response.body.data;
                    link.target = '_self';
                    link.click();
                    link.remove();
                } else {
                    this.handleOpenSnackbar(response.message, Constants.SNACKBAR_ERROR_TYPE);
                    this.setState({loading: false});
                }

            })
            .catch(error => console.log(error))
    };

    render() {
        const {classes} = this.props;
        return (
            <React.Fragment>
                <CssBaseline/>
                <Container maxWidth="sm" component={'div'} className={'pt-5'}>
                    <div className={'pt-4'}>
                        <Card
                            cardHeader={
                                {
                                    title: 'Completa los siguientes campos',
                                    subheader: ''
                                }
                            }
                            cardContent={
                                <div>
                                    <TextField
                                        required
                                        id="email"
                                        name={'email'}
                                        label="Correo electrónico"
                                        variant="outlined"
                                        value={this.state.email}
                                        type={'email'}
                                        fullWidth={true}
                                        onChange={this.handleOnChange}
                                        size={"small"}
                                        aria-autocomplete={'false'}
                                        margin={'normal'}
                                    />

                                    <GoogleReCaptchaProvider
                                        reCaptchaKey="6Lf2RsMZAAAAANFyCkCkW_fY8cUMBvmk63Id_UK_"
                                        render="explicit">
                                        <GoogleReCaptcha
                                            onVerify={this.verifyCallback}
                                        />
                                    </GoogleReCaptchaProvider>
                                    <div className={classes.wrapper}>
                                        <Button variant="contained" color="primary" fullWidth={true}
                                                onClick={this.validateForm} disabled={this.state.loading}>
                                            Enviar
                                        </Button>
                                        {this.state.loading &&
                                        <CircularProgress size={28} className={classes.buttonProgress}
                                                          color={"primary"}
                                        />}
                                    </div>
                                </div>
                            }
                        />
                    </div>
                </Container>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={this.state.snackbar.open}
                    autoHideDuration={6000}
                    onClose={this.handleCloseSnackbar}
                >
                    <SnackbarContentWrapper
                        onClose={this.handleCloseSnackbar}
                        variant={this.state.snackbar.type}
                        message={this.state.snackbar.message}
                    />
                </Snackbar>
            </React.Fragment>
        );
    }

}

export default withStyles(useStyles)(SurveyForm);