import React from 'react';
/* Imports */
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import Util from "../../../../common/Util";

am4core.addLicense("CH200331904277124");
am4core.useTheme(am4themes_animated);

class SolidGaugeChart extends React.Component {
    componentDidMount() {
        let semaphore = this.props.semaphore;
        let typeCalculation = this.props.typeCalculation;
        // Create chart instance
        let chart = am4core.create("solid-gauge-chart-" + this.props.period.id, am4charts.RadarChart);

// Add data
        chart.data = this.props.data;

        let title = chart.titles.create();
        title.text = this.props.period.year + ' | ' + this.props.period.name;
        title.fontSize = 15;
        title.marginBottom = 10;

// Make chart not full circle
        chart.startAngle = -90;
        chart.endAngle = 180;
        chart.innerRadius = am4core.percent(20);

// Set number format
        chart.numberFormatter.numberFormat = "#.#'%'";

// Create axes
        let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "category";
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.grid.template.strokeOpacity = 0;
        categoryAxis.renderer.labels.template.horizontalCenter = "right";
        categoryAxis.renderer.labels.template.fontWeight = 500;
        /*categoryAxis.renderer.labels.template.adapter.add("fill", function (fill, target) {
            let score = target.dataItem.valueX;
            let colorHex = Util.getColorFromSemaphore(score, semaphore, typeCalculation);
            return am4core.color(colorHex);
        });*/
        categoryAxis.renderer.minGridDistance = 10;

        let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.grid.template.strokeOpacity = 0;
        valueAxis.min = 0;
        valueAxis.max = 100;
        valueAxis.strictMinMax = true;

// Create series
        let series1 = chart.series.push(new am4charts.RadarColumnSeries());
        series1.dataFields.valueX = "full";
        series1.dataFields.categoryY = "category";
        series1.clustered = false;
        series1.columns.template.fill = new am4core.InterfaceColorSet().getFor("alternativeBackground");
        series1.columns.template.fillOpacity = 0.08;
        series1.columns.template.cornerRadiusTopLeft = 20;
        series1.columns.template.strokeWidth = 0;
        series1.columns.template.radarColumn.cornerRadius = 20;

        let series2 = chart.series.push(new am4charts.RadarColumnSeries());
        series2.dataFields.valueX = "value";
        series2.dataFields.categoryY = "category";
        series2.clustered = false;
        series2.columns.template.strokeWidth = 0;
        series2.columns.template.tooltipText = "{category}: [bold]{value}[/]";
        series2.columns.template.radarColumn.cornerRadius = 20;

        let score;
        series2.columns.template.adapter.add("fill", function (fill, target) {
            score = target.dataItem.valueX;
            let colorHex = Util.getColorFromSemaphore(score, semaphore, typeCalculation);
            return am4core.color(colorHex);
        });

// Add cursor
        chart.cursor = new am4charts.RadarCursor();
        this.chart = chart;
    }

    componentWillUnmount() {
        if (this.chart) {
            this.chart.dispose();
        }
    }

    render() {
        return (
            <div id={"solid-gauge-chart-" + this.props.period.id}
                 style={{height: '400px', width: "400px", display: "inline-block", marginRight: '20px'}}/>

        );
    }
}

export default SolidGaugeChart;
